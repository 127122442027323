<template>
  <div class="role">

    <div class="open-wrap">
      <new-Insert type="insert" :clearData="clearData"></new-Insert>
    </div>
  </div>
</template>

<script>
import { adminRole, adminMenu } from "@/utils/axios"
import NewInsert from '@/components/NewInsert'

export default {
  name: 'adminOpen',
  components: {
    NewInsert
  },
  inject: ['handleRefresh'],
  data () {
    return {
      formData: {
        mobile: '',
        yyuserid: '',
        sex: '',
        birth: '',
        examdate: '',
        examplace: '',
        package: ['base'],

        height: '',
        weight: '',
        bust: '',
        waist: '',
        hip: '',
        fat: '',

        area: '',
        marry: '',
        childcnt: '',
        drink: '',
        smoke: '',
        habeat: '',
        vege: '',
        habsport: '',
        sleep: '',
        disease: [
          { "KDIS": "K01", "LEVEL": "0" },
          { "KDIS": "K02", "LEVEL": "0" },
          { "KDIS": "K03", "LEVEL": "0" },
          { "KDIS": "K04", "LEVEL": "0" },
          { "KDIS": "K05", "LEVEL": "0" },
          { "KDIS": "K06", "LEVEL": "0" },
          { "KDIS": "K07", "LEVEL": "0" },
          { "KDIS": "K08", "LEVEL": "0" },
          { "KDIS": "K09", "LEVEL": "0" },
          { "KDIS": "K10", "LEVEL": "0" },
          { "KDIS": "K11", "LEVEL": "0" },
          { "KDIS": "K12", "LEVEL": "0" },
          { "KDIS": "K13", "LEVEL": "0" },
          //新增  健康评分
          { "KDIS": "K14", "LEVEL": "0" },
          { "KDIS": "K15", "LEVEL": "0" },
          { "KDIS": "K16", "LEVEL": "0" },
          { "KDIS": "K17", "LEVEL": "0" },
          { "KDIS": "K18", "LEVEL": "0" },
          { "KDIS": "K19", "LEVEL": "0" },
        ],
        oper: [],
        famdis: [],

        examine: [
          { "ne": "WBC", "ve": "", "ut": "A" },
          { "ne": "HGB", "ve": "", "ut": "A" },
          { "ne": "PLT", "ve": "", "ut": "A" },
          { "ne": "FG", "ve": "", "ut": "B" },
          { "ne": "HBA1C", "ve": "", "ut": "A" },
          { "ne": "CHOL", "ve": "", "ut": "B" },
          { "ne": "LDLC", "ve": "", "ut": "B" },
          { "ne": "HDLC", "ve": "", "ut": "B" },
          { "ne": "TG", "ve": "", "ut": "B" },
          { "ne": "GOT", "ve": "", "ut": "A" },
          { "ne": "GPT", "ve": "", "ut": "A" },
          { "ne": "ALP", "ve": "", "ut": "A" },
          { "ne": "ALB", "ve": "", "ut": "B" },
          { "ne": "CRE", "ve": "", "ut": "B" },
          { "ne": "UA", "ve": "", "ut": "B" },
          { "ne": "EGFR", "ve": "", "ut": "A" }
        ],

        operator: ''// 当前后台操作人手机号
      },
      formDataMy: {
        mobile: '',
        sex: '',
        birth: '',
        examdate: '',
        examplace: '',

        height: '',
        weight: '',
        bust: '',
        waist: '',
        hip: '',
        fat: '',

        area: '',
        marry: 1,// 固定已婚
        childcnt: '',
        drink: '',
        smoke: '',
        habeat: '',
        vege: '',
        habsport: '',
        sleep: '',
        disease: [
          { "KDIS": "K01", "LEVEL": "0" },
          { "KDIS": "K02", "LEVEL": "0" },
          { "KDIS": "K03", "LEVEL": "0" },
          { "KDIS": "K04", "LEVEL": "0" },
          { "KDIS": "K05", "LEVEL": "0" },
          { "KDIS": "K06", "LEVEL": "0" },
          { "KDIS": "K07", "LEVEL": "0" },
          { "KDIS": "K08", "LEVEL": "0" },
          { "KDIS": "K09", "LEVEL": "0" },
          { "KDIS": "K10", "LEVEL": "0" },
          { "KDIS": "K11", "LEVEL": "0" },
          { "KDIS": "K12", "LEVEL": "0" },
          { "KDIS": "K13", "LEVEL": "0" }
        ],
        oper: [],
        famdis: [],

        examine: [
          { "ne": "WBC", "ve": "", "ut": "A" },
          { "ne": "HGB", "ve": "", "ut": "A" },
          { "ne": "PLT", "ve": "", "ut": "A" },
          { "ne": "FG", "ve": "", "ut": "B" },
          { "ne": "HBA1C", "ve": "", "ut": "A" },
          { "ne": "CHOL", "ve": "", "ut": "B" },
          { "ne": "LDLC", "ve": "", "ut": "B" },
          { "ne": "HDLC", "ve": "", "ut": "B" },
          { "ne": "TG", "ve": "", "ut": "B" },
          { "ne": "GOT", "ve": "", "ut": "A" },
          { "ne": "GPT", "ve": "", "ut": "A" },
          { "ne": "ALP", "ve": "", "ut": "A" },
          { "ne": "ALB", "ve": "", "ut": "B" },
          { "ne": "CRE", "ve": "", "ut": "B" },
          { "ne": "UA", "ve": "", "ut": "B" },
          { "ne": "EGFR", "ve": "", "ut": "A" }
        ],

        preweek: '',
        preweight: '',
        prewaist: '',
        prebust: '',
        prehip: '',
        prefat: '',

        bsex: '',
        mage: '',
        week: '',
        bweight: '',
        aps1: '',

        predis2: [],

        operator: '',// 当前后台操作人手机号

        sbpCombo: true// 产品组合勾选(前台使用，用来确认勾选产品组合-基础套餐体检)

      },// 数据存储
      rules: {
        name: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ],
        power: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ]
      },
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    clearData () {
      this.formData = {
        mobile: '',
        yyuserid: '',
        sex: '',
        birth: '',
        examdate: '',
        examplace: '',
        package: ['base'],

        height: '',
        weight: '',
        bust: '',
        waist: '',
        hip: '',
        fat: '',

        area: '',
        marry: '',
        childcnt: '',
        drink: '',
        smoke: '',
        habeat: '',
        vege: '',
        habsport: '',
        sleep: '',
        disease: [
          { "KDIS": "K01", "LEVEL": "0" },
          { "KDIS": "K02", "LEVEL": "0" },
          { "KDIS": "K03", "LEVEL": "0" },
          { "KDIS": "K04", "LEVEL": "0" },
          { "KDIS": "K05", "LEVEL": "0" },
          { "KDIS": "K06", "LEVEL": "0" },
          { "KDIS": "K07", "LEVEL": "0" },
          { "KDIS": "K08", "LEVEL": "0" },
          { "KDIS": "K09", "LEVEL": "0" },
          { "KDIS": "K10", "LEVEL": "0" },
          { "KDIS": "K11", "LEVEL": "0" },
          { "KDIS": "K12", "LEVEL": "0" },
          { "KDIS": "K13", "LEVEL": "0" }
        ],
        oper: [],
        famdis: [],

        examine: [
          { "ne": "WBC", "ve": "", "ut": "A" },
          { "ne": "HGB", "ve": "", "ut": "A" },
          { "ne": "PLT", "ve": "", "ut": "A" },
          { "ne": "FG", "ve": "", "ut": "B" },
          { "ne": "HBA1C", "ve": "", "ut": "A" },
          { "ne": "CHOL", "ve": "", "ut": "B" },
          { "ne": "LDLC", "ve": "", "ut": "B" },
          { "ne": "HDLC", "ve": "", "ut": "B" },
          { "ne": "TG", "ve": "", "ut": "B" },
          { "ne": "GOT", "ve": "", "ut": "A" },
          { "ne": "GPT", "ve": "", "ut": "A" },
          { "ne": "ALP", "ve": "", "ut": "A" },
          { "ne": "ALB", "ve": "", "ut": "B" },
          { "ne": "CRE", "ve": "", "ut": "B" },
          { "ne": "UA", "ve": "", "ut": "B" },
          { "ne": "EGFR", "ve": "", "ut": "A" }
        ],

        operator: ''// 当前后台操作人手机号
      }
    },

  }
}
</script>

<style scoped></style>
<style lang="scss" scoped>
.role {
  padding: 24px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border: 12px solid #F2F4FA;

  .el-dialog {

    .dialog-title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #293033;
      text-align: center;
    }

    .all-power {
      -webkit-appearance: none;
      background-color: #FFF;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
      width: 100%;
    }

    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: center;

      .cancel-btn {
        width: 94px;
        height: 34px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #6883FB;
        border: 1px solid #6883FB;
        border-radius: 17px;
        background-color: #fff;
      }

      .submit-btn {
        width: 94px;
        height: 34px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fff;
        border: none;
        border-radius: 17px;
        background-color: #6883FB;
        margin-left: 16px;
      }

      .el-button {
        padding: 9px 15px;
      }
    }
  }
}
</style>
